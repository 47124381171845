




















import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import sidebarFinance from '@/components/sidebar-finance.vue'; // @ is an alias to /src

@Component({
  components: {
    Header,
    sidebarFinance,
  },
})
export default class Finance extends Vue {
  
  mounted() {
  }
}
